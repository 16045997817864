import { render, staticRenderFns } from "./Join_c.vue?vue&type=template&id=cc63bd1e&scoped=true&"
var script = {}
import style0 from "./Join_c.vue?vue&type=style&index=0&id=cc63bd1e&prod&land=scss&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cc63bd1e",
  null
  
)

export default component.exports